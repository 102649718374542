import Scrollspy from 'react-scrollspy'
import React from "react"

export default (props) => (
	<nav className={props.stickyNav ? 'stickyNav nav' : 'nav'}>
	  <Scrollspy  
	  items={ ['section-1', 'section-2', 'section-3', 'section-4', 'section-5', 'section-6'] } 
	  currentClassName="is-current" offset={0}>
	    <li ><a href="#section-1">The Omega-3 Quiz</a></li>
	    <li ><a href="#section-2">Downloadable Resources</a></li>
	    <li><a href="#section-3">VASCEPA Savings Card</a></li>
	    <li><a href="#section-4">Important Information About Dispensing VASCEPA</a></li>
	    <li><a href="#section-5">Virtual Speaker Programs</a></li>
	    <li><a href="#section-6">On-Demand Expert Perspectives</a></li>
	  </Scrollspy>
	</nav>
)
