import React, { Component } from "react"

import HeroImage from '../../components/resources-pharmacists/HeroImage'
import Main from '../../components/resources-pharmacists/Main'
import SideNavigation from '../../components/resources-pharmacists/SideNavigation'

import Header from "../../components/header/Header"
import ISI from '../../components/isi/ISI'
import Footer from '../../components/footer/Footer'
import Modal from '../../components/modal/Modal'
import HomeModal from '../../components/modal/HomeModal'
import SEO from "../../components/seo"
import { Waypoint } from 'react-waypoint';
import Loader from "../../components/loader"



/* styles */
import "../../components/1.1_vascepa_patients/styles.css"
import Footnotes from "../../components/footnotes/Footnotes";

const footnotes = ['']

export default class VascepaPatient extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null,
       hideNav: false
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked')
  }

  goModal = (evt) => {
    // console.log('clicked')
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: evt}))

  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }
  handleWaypointEnter = () => {
    this.setState(() => ({ hideNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ hideNav: true }))
  }


  render() {
    return (
      <>
      <Loader>
      <SEO
        title="Patients Who May Benefit From VASCEPA CV Risk Reduction"
        keywords={[""]}
        description="Discover which patient types may benefit from the unprecedented CV risk reduction of VASCEPA. Many of your CV-risk patients may benefit from VASCEPA. Use these profiles to help decide."/>
        <HomeModal/>

        <Modal modal={this.state.modal} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick}/>
        <Waypoint
         onEnter={this.handleWaypointEnter}
         onLeave={this.handleWaypointLeave}/>
        <div className="wrapper">
          <div className="grid">
            <SideNavigation stickyNav={this.state.hideNav}/>
            <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
          </div>
        </div>
        <ISI scrollPos={this.state.unstick} />
        <div className="ref-outer">
        <Footnotes footnotes={footnotes}/>
        <div className="footnotes-container">
          <div className="footnotes">
            <div className="cv-footnote">
            CV=cardiovascular; IPE=icosapent ethyl.
            </div>
          </div>
        </div>
        <div className="footnotes-container">
          <div className="footnotes">
            <div style={{textIndent:"-5px"}} className="cv-footnote">
              *Universal Pharmacy Card (UPC) may be applied for any eligible patient by entering all 4 codes into the notes section of an e-prescription.
            </div>
            <div className="cv-footnote"><strong>Reference: 1</strong>. VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021. </div>
          </div>
        </div>

        {/* <div className="footnotes-container">
          <div className='references'>
              <p className="cv-footnote"><strong>References:
                1.</strong> VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2019.
                <strong> 2. </strong>Sabatine MS, Giugliano RP, Keech AC, et al. Evolocumab and clinical outcomes in patients with cardiovascular disease.  <i>N Engl J Med</i>. 2017;376(18):1713-1722.
                <strong> 3. </strong>Bhatt DL, Steg PG, Miller M, et al; for the REDUCE-IT Investigators. Cardiovascular risk reduction with icosapent ethyl for hypertriglyceridemia. <i>N Engl J Med</i>. 2019;380(1):11-22.
                <strong> 4. </strong>Cannon CP, Blazing MA, Giugliano RP, et al. Ezetimibe added to statin therapy after acute coronary syndromes.  <i>N Engl J Med</i>. 2015;372(25):2387-2397.
                <strong> 5. </strong>Virani SS, Alonso A, Benjamin EJ, et al. Heart Disease and Stroke Statistics—2020 Update: A Report From the American Heart Association. <i>Circulation</i>. 2020;141(9):e139-e596.
                <strong> 6. </strong>American Heart Association. Cardiovascular disease: a costly burden for America. Projections through 2035. <span style={{wordBreak:"break-all"}}>https://healthmetrics.heart.org/wp-content/uploads/2017/10/Cardiovascular-Disease-A-Costly-Burden.pdf.</span> Accessed October 22, 2020.
                <strong> 7. </strong>Miller M, Cannon CP, Murphy SA, Qin J, Ray KK, Braunwald E; for the PROVE IT-TIMI 22 Investigators. Impact of triglyceride levels beyond low-density lipoprotein cholesterol after acute coronary syndrome in the PROVE IT-TIMI 22 trial. <i>J Am Coll Cardiol.</i> 2008;51(7):724-730.
                <strong> 8. </strong>ORIGIN Trial Investigators; Bosch J, Gerstein HC, Dagenais GR, et al. n–3 fatty acids and cardiovascular outcomes in patients with dysglycemia. <i>N Engl J Med.</i> 2012;367(4):309-318.
                <strong> 9. </strong>Risk and Prevention Study Collaborative Group. n–3 fatty acids in patients with multiple cardiovascular risk factors. <i>N Engl J Med.</i> 2013;368(19):1800-1808.
                <strong> 10. </strong>Rauch B, Schiele R, Schneider S, et al; for the OMEGA Study Group. OMEGA, a randomized, placebo-controlled trial to test the effect of highly purified omega-3 fatty acids on top of modern guideline-adjusted therapy after myocardial infarction. <i>Circulation.</i> 2010;122(21):2152-2159.
                <strong> 11. </strong>ASCEND Study Collaborative Group; Bowman L, Mafham M, Wallendszus K, et al. Effects of n−3 Fatty Acid Supplements in Diabetes Mellitus. <i>N Engl J Med.</i> 2018;379(16):1540-1550.
                <strong> 12. </strong>Manson JE, Cook NR, Lee IM, et al. Marine n−3 Fatty Acids and Prevention of Cardiovascular Disease and Cancer. <i>N Engl J Med.</i> 2019;380(1):23-32.
                </p>
          </div>
        </div> */}
        </div>
        <Footer code="US-VAS-03038v7"/>
        </Loader>

      </>
    )
  }
}
