import React, { Component } from "react"


export default class Footnotes extends Component {

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }
  render() {
    const footnotes = this.props.footnotes;
    return (
        <article className="footnotes">
            {footnotes ? footnotes.map( (footnote,ind) => <p key={ind} className="cv-footnote">{footnote}</p>) : null}
        </article>    
    )
  }
}
