import React from "react"
import { Link } from "gatsby"
import Canto from '../../images/resources/canto.png'
import DawCode from '../../images/resources/daw-code.png'
import DawCodeNew from '../../images/resources/daw-code-new.png'
import Omega3 from '../../images/resources/omega3.png'
import CardFPO from '../../images/graphs/desktop/savingscard.jpg'
import DispensingVascepa from '../../images/resources/dispensing-vascepa.png'
import FactSheetPDF from '../../pdfs/VASCEPA_Retail_Fact_Sheet-April_2022.pdf'
import TradePharmacyMemo from '../../images/resources/trade-pharmacy-memo.jpg'
import RetailFactSheet from '../../images/resources/retail-fact-sheet.jpg'
import TradePharmacyMemoPDF from '../../pdfs/VASCEP35548-Trade_Pharmacy_Memo_without_QR_Bar_Code_LAY_C00_01.pdf'

/* styles */
import "../resources-hcps/styles.css"

export default (props) => {
  const collapsiblewidth = props.isMobile ? '100%' : '505px';

  return (
    <main className="main">
      <section id="section-1" className="speaker-program-section">
        <h4 className="section-header m-t-60">The Omega-3 Quiz</h4>
        <div className="p-x-15">
          <p>
            <strong>
              You may not know as much as you think about omega-3s. Take this
              short quiz to find out how omega-3s stack up against other
              treatments.
            </strong>
          </p>
          <Link to="/quiz/pharmacist/" className="cta-btn">
            Take the quiz
          </Link>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />
      <section id="section-2">
        <h4 className="section-header m-t-60">Downloadable Resources</h4>
        <div className="resourceGrid-container pharma">
          <div className="resourceGrid ">
            <h3>
              Indication Differences Between VASCEPA and Generic{" "}
              <span className="nowrap">Omega-3s</span>
            </h3>
            <img
              src={Omega3}
              alt="Indication Differences Between VASCEPA and Generic Omega-3s"
            />
            <p style={{ display: "inline-block" }}>
              Information on indication differences for VASCEPA and Lovaza
              <sup>&reg;</sup> (omega-3-acid ethyl esters) and its generics
            </p>
            <a
              target="_blank"
              href=" https://amarincorp.com/docs/US-VAS-03287_Pharm_Generic_Omega-3_Flashcard_Jun_21_with_PI.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>Common DAW Codes</h3>
            <img src={DawCodeNew} alt="Common DAW Codes" />
            <p>
              A quick and easy reference sheet for pharmacists to use to ensure
              that VASCEPA is dispensed properly
            </p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/Pharm_DAW_Codes.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>

          <div className="resourceGrid ">
            <h3>VASCEPA Access & Coverage</h3>
            <img src={DawCode} alt="VASCEPA Access & Coverage" />
            <p>Information on VASCEPA access and savings</p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/US-VAS-03639_Pharm_Leave_Behind_with_PI.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>Pharmacy Education Guide</h3>
            <img src={TradePharmacyMemo} alt="Pharmacy Education Guide" />
            <p>
              See how your patients can save more on VASCEPA compared to the
              generic
            </p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/Pharmacy_Education_Guide.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
          <div className="resourceGrid ">
            <h3>Retail Fact Sheet</h3>
            <img src={RetailFactSheet} alt="Retail Fact Sheet" />

            <p className="placeholder-text">
              See the retail information for VASCEPA
            </p>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/retail_fact_sheet.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-3">
        <h4 className="section-header">VASCEPA Savings Card</h4>
        <div className="resource-savings-card">
          <img src={CardFPO} alt="VASCEPA Savings Card" />
          <p style={{ "margin-top": "0px" }}>
            Learn how eligible patients can sign up to save on VASCEPA with the
            universal VASCEPA Savings Card.*
            <br />
            <br />
            For eligible patients only. Maximum savings and other terms and conditions apply. See{" "}
            <a href="https://vascepa.copaysavingsprogram.com" target="_blank">
              <strong>https://vascepa.copaysavingsprogram.com</strong>
            </a>{" "}
            for details.
          </p>
          <div>
            <a
              target="_blank"
              href="https://amarincorp.com/docs/VASCEPA_Savings_Card.pdf"
              className="cta-btn"
            >
              Download card
            </a>
          </div>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />
      <section id="section-4">
        <h4 className="section-header">
          Important Information About Dispensing VASCEPA
        </h4>
        <div className="resource-savings-card">
          <img
            src={DispensingVascepa}
            alt="Important Information About Dispensing VASCEPA"
          />
          <p className="with-list" style={{ "margin-top": "0px" }}>
            <ul>
              <li>
                When generic IPE is automatically substituted for VASCEPA, but
                the generic is not in stock, some patients won’t come back for
                their prescription—interrupting their therapy
              </li>
              <li>
                Stock and dispense VASCEPA as written to minimize any disruption
                in patient care
              </li>
              <li>
                Watch out for unintended substitutions and claim rejections.
                Look for “Dispense as written (DAW-1)” or “Brand medically
                necessary” to help ensure your patients are getting the CV
                benefits of VASCEPA<sup>1</sup>
              </li>
            </ul>
          </p>
          <div class="main t--85 t-mob--20 m-b-0">
            <a
              target="_blank"
              href="https://amarincorp.com/docs/Pharm_Insurance_Handout.pdf"
              className="cta-btn"
            >
              Download
            </a>
          </div>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-5" className="speaker-program-section">
        <h4 className="section-header">Virtual Speaker Programs</h4>
        <div className="p-x-15">
          <p>
            <strong>
              Join your peers at convenient times as they discuss how your
              statin-treated patients can benefit from VASCEPA
            </strong>
          </p>
          <a
            target="_blank"
            href="https://events.medpt.com/vascepa/webcast"
            className="cta-btn"
          >
            Register now
          </a>
        </div>
      </section>
      <hr className="m-y-50 mobile-m-y-25" />

      <section id="section-6">
        <h4 className="section-header">On-Demand Expert Perspectives</h4>
        <div className="resourceGrid-container">
          <div className="resourceGrid ">
            <h3>Expert Perspective: Dr. Canto</h3>
            <img src={Canto} alt="Dr. Canto headshot" />
            <p>
              Watch Dr. John Canto highlight the urgency to treat Persistent
              Cardiovascular Risk <span className="nowrap">(P-CVR)</span> and
              the role of VASCEPA
            </p>
            <a
              target="_blank"
              href="https://onlinexperiences.com/scripts/Server.nxp?LASCmd=AI:4;F:QS!10100&ShowUUID=63B2387E-E9B7-44CC-BC91-A33F78F02F46"
              className="cta-btn"
            >
              Register and watch
            </a>
          </div>
        </div>
      </section>
    </main>
  )
}
